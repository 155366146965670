import { ReactComponent as sdgIcon01 } from './SDG-icon-DE-01.svg';
import { ReactComponent as sdgIcon02 } from './SDG-icon-DE-02.svg';
import { ReactComponent as sdgIcon03 } from './SDG-icon-DE-03.svg';
import { ReactComponent as sdgIcon04 } from './SDG-icon-DE-04.svg';
import { ReactComponent as sdgIcon05 } from './SDG-icon-DE-05.svg';
import { ReactComponent as sdgIcon06 } from './SDG-icon-DE-06.svg';
import { ReactComponent as sdgIcon07 } from './SDG-icon-DE-07.svg';
import { ReactComponent as sdgIcon08 } from './SDG-icon-DE-08.svg';
import { ReactComponent as sdgIcon09 } from './SDG-icon-DE-09.svg';
import { ReactComponent as sdgIcon10 } from './SDG-icon-DE-10.svg';
import { ReactComponent as sdgIcon11 } from './SDG-icon-DE-11.svg';
import { ReactComponent as sdgIcon12 } from './SDG-icon-DE-12.svg';
import { ReactComponent as sdgIcon13 } from './SDG-icon-DE-13.svg';
import { ReactComponent as sdgIcon14 } from './SDG-icon-DE-14.svg';
import { ReactComponent as sdgIcon15 } from './SDG-icon-DE-15.svg';
import { ReactComponent as sdgIcon16 } from './SDG-icon-DE-16.svg';
import { ReactComponent as sdgIcon17 } from './SDG-icon-DE-17.svg';

const sdgIcons = {
    '1': { imageSource: sdgIcon01, altText: 'Keine Armut' },
    '2': { imageSource: sdgIcon02, altText: 'Kein Hunger' },
    '3': { imageSource: sdgIcon03, altText: 'Gesundheit und Wohlergehen' },
    '4': { imageSource: sdgIcon04, altText: 'Hochwertige Bildung' },
    '5': { imageSource: sdgIcon05, altText: 'Geschlechter-Gleichheit' },
    '6': { imageSource: sdgIcon06, altText: 'Sauberes Wasser und Sanitär-Einrichtungen' },
    '7': { imageSource: sdgIcon07, altText: 'Bezahlbare und saubere Energie' },
    '8': { imageSource: sdgIcon08, altText: 'Menschenwürdige Arbeit und Wirtschaftswachstum' },
    '9': { imageSource: sdgIcon09, altText: 'Industrie, Innovation und Infrastruktur' },
    '10': { imageSource: sdgIcon10, altText: 'Weniger Ungleichheiten' },
    '11': { imageSource: sdgIcon11, altText: 'Nachhaltige Städte und Gemeinden' },
    '12': { imageSource: sdgIcon12, altText: 'Nachhaltige/r Konsum und Produktion' },
    '13': { imageSource: sdgIcon13, altText: 'Maßnahmen zum Klimaschutz' },
    '14': { imageSource: sdgIcon14, altText: 'Leben unter Wasser' },
    '15': { imageSource: sdgIcon15, altText: 'Leben an Land' },
    '16': { imageSource: sdgIcon16, altText: 'Frieden, Gerechtigkeit und starke Institutionen' },
    '17': { imageSource: sdgIcon17, altText: 'Partnerschaften zur Erreichung der Ziele' },
};

interface Props {
    sdgId: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17;
}

export const SdgIcon = (props: Props) => {
    const TheIcon = sdgIcons[props.sdgId].imageSource;

    return <TheIcon title={sdgIcons[props.sdgId].altText} />;
};
