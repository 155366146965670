import { useState } from 'react';
import { AppState } from '../model/AppState';

export const useAppState = () => {
    const [state, setState] = useState<AppState>({
        filtersVisible: false,
        selectedFormats: [],
        selectedTopics: [],
        selectedTargetGroups: [],
        selectedLocationTypes: [],
    });

    return {
        ...state,
        setFiltersVisible: (filtersVisible: boolean) => {
            setState({ ...state, filtersVisible });
        },
        setSelectedFormats: (selectedFormats: string[]) => {
            setState({ ...state, selectedFormats });
        },
        setSelectedTopics: (selectedTopics: string[]) => {
            setState({ ...state, selectedTopics });
        },
        setSelectedTargetGroups: (selectedTargetGroups: number[]) => {
            setState({ ...state, selectedTargetGroups });
        },
        setSelectedLocationTypes: (selectedLocationTypes: number[]) => {
            setState({ ...state, selectedLocationTypes });
        },
    };
};
