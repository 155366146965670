import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Main } from './Main';

(function () {
    if (document.location.search.includes && document.location.search.includes('elementor-preview')) {
        console.log('elementor detected. not showing program.');
        return;
    }

    const nodeStartseite = document.getElementById('zww-outlet-startseite');
    const nodeProgrammseite = document.getElementById('zww-outlet-programmseite');
    const node = nodeStartseite ?? nodeProgrammseite;

    if (node === null) {
        console.warn('zww-outlet node not found');
        return;
    }

    ReactDOM.render(
        <React.StrictMode>
            <HashRouter>
                <Main short={nodeStartseite !== null} year2023={node.classList.contains('zww-year-2023')} />
            </HashRouter>
        </React.StrictMode>,
        node
    );
})();
