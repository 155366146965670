import { Session } from '../model/Session';

interface Props {
    links: Session['host']['links'];
}

export const HostLinkBox = (props: Props) => {
    if (props.links === undefined) {
        return null;
    }

    return (
        <div className="zww-link-box">
            {props.links.host ? (
                <a href={props.links.host} target="_blank" rel="noreferrer">
                    <i className="fas fa-home"></i>
                </a>
            ) : null}
            {props.links.twitter ? (
                <a href={props.links.twitter} target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter"></i>
                </a>
            ) : null}
            {props.links.facebook ? (
                <a href={props.links.facebook} target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook"></i>
                </a>
            ) : null}
            {props.links.instagram ? (
                <a href={props.links.instagram} target="_blank" rel="noreferrer">
                    <i className="fab fa-instagram"></i>
                </a>
            ) : null}
            {props.links.youtube ? (
                <a href={props.links.youtube} target="_blank" rel="noreferrer">
                    <i className="fab fa-youtube"></i>
                </a>
            ) : null}
        </div>
    );
};
