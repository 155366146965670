import { SdgIcon } from './SdgIcon';
import './sdg-icon-list.scss';

interface Props {
    sdgs?: ReadonlyArray<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>;
}

export const SdgIconList = (props: Props) => {
    if (!props.sdgs || props.sdgs.length === 0) {
        return null;
    }

    const introText =
        props.sdgs.length === 1
            ? 'Dieses Nachhaltigkeitsziel wird gefördert:'
            : 'Diese Nachhaltigkeitsziele werden gefördert:';

    return (
        <div className="zww-sdg-box">
            {introText}
            <ul className="zww-sdg-icon-list">
                {props.sdgs.map((sdgId) => (
                    <li key={sdgId}>
                        <SdgIcon sdgId={sdgId} />
                    </li>
                ))}
            </ul>
        </div>
    );
};
