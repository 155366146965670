import { Routes, Route } from 'react-router-dom';
import { Session } from './components/Session';
import { SessionTable } from './components/SessionTable';
import {} from './model/AppState';
import { useSessionFeed } from './hooks/sessionFeed';
import { Host } from './components/Host';
import { SessionFeedStatus } from './model/SessionFeed';
import { useAppState } from './hooks/appState';
import { SessionContext } from './components/SessionContext';

interface Props {
    short?: boolean;
    year2023?: boolean;
}

export const Main = (props: Props) => {
    const sessionFeed = useSessionFeed(props.year2023 ?? false);
    const appState = useAppState();

    switch (sessionFeed.status) {
        case SessionFeedStatus.FetchingSessionData:
            return <>Daten werden geladen ...</>;

        case SessionFeedStatus.InitializationFailed:
            return <>Beim Laden der Daten ist ein Fehler aufgetreten.</>;

        case SessionFeedStatus.InitializationComplete:
            break;
    }

    return (
        <SessionContext.Provider value={sessionFeed.data}>
            <Routes>
                <Route path="/">
                    <Route path="sessions">
                        <Route path="" element={<SessionTable {...appState} short={props.short} />} />
                        <Route path="by-date/:date" element={<SessionTable {...appState} short={props.short} />} />
                        <Route path=":sessionId" element={<Session />} />
                    </Route>
                    <Route path="veranstalter">
                        <Route path=":hostId" element={<Host />} />
                    </Route>
                    <Route path="" element={<SessionTable {...appState} short={props.short} />} />
                </Route>
            </Routes>
        </SessionContext.Provider>
    );
};
