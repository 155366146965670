export interface Location {
    name: string;
    streetNo: string;
    zipcode: string;
    city: string;
    lat?: number;
    lng?: number;
}

export interface Session {
    id: number;
    start: string;
    end: string | null;
    cancelled: boolean;
    highlight: boolean;
    onlineOnly: boolean;
    locationType?: number;
    host: {
        id: number;
        name: string;
        infotext?: string;
        logo?: string;
        links?: {
            host?: string;
            facebook?: string;
            twitter?: string;
            youtube?: string;
            instagram?: string;
        };
    };
    title: string;
    location?: Location;
    description?: {
        long?: string;
    };
    images: {
        teaser?: { thumbnail: string; view: string };
    };
    links?: {
        event?: string;
    };
    eventFormat?: string;
    eventFormatSecondary?: string;
    eventTopic?: string;
    targetGroups?: number[];
    sdgs?: ReadonlyArray<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>;
}

export type SessionList = Session[];
export type PartitionedSessionList = { [key: string]: SessionList };

function extractDate(session: Session): string {
    return new Date(session.start).toISOString().substring(0, 10);
}

export namespace Session {
    export function partitionByDate(sessions: SessionList): PartitionedSessionList {
        return sessions.reduce((acc: PartitionedSessionList, session) => {
            const key = extractDate(session);
            acc[key] = acc[key] || [];
            acc[key].push(session);
            return acc;
        }, {} as PartitionedSessionList);
    }

    export function startTimeComparator(a: Session, b: Session) {
        if (a.start < b.start) return -1;
        if (a.start > b.start) return 1;
        return 0;
    }
}
