import { SessionList } from './Session';

export enum SessionFeedStatus {
    FetchingSessionData,
    InitializationFailed,
    InitializationComplete,
}

export interface SessionFeedData {
    categories: {
        eventTopic: string[];
        eventFormat: string[];
        locationType?: { [id: number]: string };
        targetGroup?: { [id: number]: string };
    };
    sessions: SessionList;
}

export type SessionFeed =
    | { status: SessionFeedStatus.FetchingSessionData }
    | { status: SessionFeedStatus.InitializationFailed }
    | {
          status: SessionFeedStatus.InitializationComplete;
          data: SessionFeedData;
      };
