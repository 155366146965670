import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { Session as SessionModel } from '../model/Session';

import 'leaflet/dist/leaflet.css';

// work-around react-leaftlet icon issues, see https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
delete (window as any).L.Icon.Default.prototype._getIconUrl;
(window as any).L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface SessionMiniMapProps {
    session: SessionModel;
}

export const SessionMiniMap = ({ session }: SessionMiniMapProps) => {
    if (!session.location?.lat || !session.location.lng) {
        return null;
    }

    return (
        <MapContainer
            center={[session.location.lat, session.location.lng]}
            zoom={14}
            style={{ width: '100%', height: '380px' }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[session.location.lat, session.location.lng]}></Marker>
        </MapContainer>
    );
};
