import { createContext, useContext } from 'react';
import { SessionList } from '../model/Session';
import { SessionFeedData } from '../model/SessionFeed';

export const SessionContext = createContext<SessionFeedData | undefined>(undefined);

export const useSessionContext = (): SessionFeedData => {
    const value = useContext(SessionContext);

    if (value === undefined) {
        throw new Error('useSessionContext used without wrapping provider');
    }

    return value;
};

export const useSessions = (): SessionList => {
    const { sessions } = useSessionContext();
    return sessions;
};
