import { useEffect, useState } from 'react';
import { SessionFeed, SessionFeedStatus } from '../model/SessionFeed';
import { mapValues, keyBy } from 'lodash';

const SESSION_DATA_2023_URL = 'https://zukunftswoche-mainfranken.de/sessions-2023.json';

const SESSION_DATA_URL = 'https://event-tool.zukunftswoche-mainfranken.de/export/session.json';

export const useSessionFeed = (year2023: boolean) => {
    const [state, setState] = useState<SessionFeed>({ status: SessionFeedStatus.FetchingSessionData });

    useEffect(() => {
        fetch(year2023 ? SESSION_DATA_2023_URL : SESSION_DATA_URL).then(async (res) => {
            const data = await res.json();

            if (data.categories.locationType) {
                data.categories.locationType = mapValues(
                    keyBy(data.categories.locationType, 'id'),
                    ({ name }: { name: string }) => name
                );
            }

            if (data.categories.targetGroup) {
                data.categories.targetGroup = mapValues(
                    keyBy(data.categories.targetGroup, 'id'),
                    ({ name }: { name: string }) => name
                );
            }

            setState((oldState) => ({ ...oldState, status: SessionFeedStatus.InitializationComplete, data }));
        });
    }, [year2023]);

    return state;
};
