import { Link } from 'react-router-dom';
import { Session } from '../model/Session';
import { formatTime } from '../util/dateUtil';

interface Props {
    shortListingHref?: string;
    sessions: Session[];
}

function compareHighlightAndStartTime(a: Session, b: Session) {
    if (a.highlight && !b.highlight) {
        return -1;
    }

    if (!a.highlight && b.highlight) {
        return 1;
    }

    return Session.startTimeComparator(a, b);
}

function formatLocation(session: Session) {
    if (!session.location) {
        return 'nur online';
    }

    if (session.location.name.includes(session.location.city)) {
        return session.location.name;
    }

    return `${session.location.city}, ${session.location.name}`;
}

export const SessionList = (props: Props) => {
    let listing = props.sessions.sort(compareHighlightAndStartTime);
    const numSessions = listing.length;

    if (props.shortListingHref) {
        listing = listing.slice(0, 4);
    }

    return (
        <>
            <div className={`zww-flex ${props.shortListingHref ? 'zww-short-listing' : ''}`}>
                {listing.map((session) => (
                    <div className="zww-list-item-3" key={session.id}>
                        <div className="event-content">
                            <Link to={`/sessions/${session.id}`}>
                                <>
                                    <div style={{ position: 'relative' }}>
                                        {session.cancelled ? (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '3rem',
                                                    width: '100%',
                                                    background: 'red',
                                                    color: 'white',
                                                    padding: '0.5em',
                                                    fontWeight: 600,
                                                    fontSize: '1.8rem',
                                                }}
                                            >
                                                abgesagt
                                            </span>
                                        ) : null}
                                        <img
                                            className="zww-teaser-thumbnail"
                                            src={session.images.teaser?.thumbnail}
                                            alt=""
                                        />
                                    </div>

                                    <div className="zww-time-location">
                                        {formatTime(session.start)}
                                        {' // '}
                                        {formatLocation(session)}
                                    </div>
                                    <h3>{session.title}</h3>
                                    <div className="zww-details-hint">&gt; Details</div>
                                </>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            {props.shortListingHref && numSessions > 3 && (
                <div className={`zww-excess-sessions ${numSessions === 4 ? 'zww-excess-sesions-4' : ''}`}>
                    <a href={props.shortListingHref}>
                        weitere Events <i className="fas fa-arrow-right"></i>
                    </a>
                </div>
            )}
        </>
    );
};
