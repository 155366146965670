import { useParams } from 'react-router-dom';
import { SessionList } from './SessionList';
import { Fragment, useEffect, useRef } from 'react';
import { HostLinkBox } from './HostLinkBox';
import { useSessions } from './SessionContext';

export const Host = () => {
    const ref = useRef<HTMLDivElement>(null);

    const sessions = useSessions();

    const params = useParams();
    const hostId = parseInt(params.hostId ?? '', 10);
    const sessionsOfHost = sessions.filter((x) => x.host.id === hostId);

    useEffect(() => {
        if (ref.current && ref.current.scrollIntoView) {
            ref.current.scrollIntoView();
        }
    }, [hostId]);

    if (!sessionsOfHost.length) {
        return <span>Veranstalter nicht gefunden :(</span>;
    }

    const host = sessionsOfHost[0].host;

    return (
        <>
            <div ref={ref} className="zww-host-detail zww-clearfix">
                {host.logo && <img className="zww-host-image" src={host.logo} alt="" />}

                <h3>{host.name}</h3>

                {host.infotext?.split(/\r?\n\r?\n/).map((ptext, i) => (
                    <p key={i}>
                        {ptext.split(/\r?\n/).map((line, i) => (
                            <Fragment key={i}>
                                {line}
                                <br />
                            </Fragment>
                        ))}
                    </p>
                ))}

                <HostLinkBox links={host.links} />
            </div>

            <h3 className="zww-sessions-of-host">Veranstaltungen von {host.name}</h3>
            <SessionList sessions={sessionsOfHost} />
        </>
    );
};
