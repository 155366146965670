import { Link } from 'react-router-dom';
import { formatDate, formatWeekday } from '../../util/dateUtil';
import './day-selector.scss';

interface Props {
    dayList: string[];
    selectedDay: string | undefined;
}

export const DaySelector = (props: Props) => (
    <div className="zww-day-selector zww-flex">
        <Link to="/sessions/" className={props.selectedDay === undefined ? 'zww-selected' : ''}>
            Alle
            <span className="zww-day-select-line2">
                <br />
                Tage
            </span>
        </Link>
        {props.dayList.map((day) => {
            const weekday = formatWeekday(day);
            return (
                <Link
                    to={`/sessions/by-date/${day}`}
                    key={day}
                    className={props.selectedDay === day ? 'zww-selected' : ''}
                >
                    {weekday.substring(0, 2)}
                    <span className="zww-weekday-long">{weekday.substring(2)}</span>
                    <span className="zww-day-select-line2">
                        <br />
                        {formatDate(day)}
                    </span>
                </Link>
            );
        })}
    </div>
);
